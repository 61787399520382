import React, { useState, useRef, useEffect } from "react";
import "./App.css";

import TSXOptionsComponent from "./tsxOptionsComponent";
import TSXDescriptionsComponent from "./tsxDescriptionsComponent";
import { usePersistedState } from "./usePersistedState";

type UserConfigKey = keyof typeof UserConfig;
export type UserConfigInterface = {
  [key in UserConfigKey]: {
    optionType: optionTypeEnum;
    default: string | boolean | string[];
    userChoice: string | boolean | string[] | null;
  };
};

export enum optionTypeEnum {
  BOOLEAN,
  ENUM_SINGLE,
  ENUM_MULTIPLE,
  INPUT,
}

const UserConfig = {
  incremental: {
    optionType: optionTypeEnum.BOOLEAN,
    default: false,
    userChoice: null,
  },
  target: {
    optionType: optionTypeEnum.ENUM_SINGLE,
    default: "ES3",
    userChoice: null,
  },
  module: {
    optionType: optionTypeEnum.ENUM_SINGLE,
    default: "commonjs",
    userChoice: null,
  },
  lib: {
    optionType: optionTypeEnum.ENUM_MULTIPLE,
    default: ["ES5", "DOM", "ScriptHost"],
    userChoice: [],
  },
  allowJs: {
    optionType: optionTypeEnum.BOOLEAN,
    default: false,
    userChoice: null,
  },
  checkJs: {
    optionType: optionTypeEnum.BOOLEAN,
    default: false,
    userChoice: null,
  },
  jsx: {
    optionType: optionTypeEnum.ENUM_SINGLE,
    default: "react",
    userChoice: null,
  },
  declaration: {
    optionType: optionTypeEnum.BOOLEAN,
    default: true,
    userChoice: null,
  },
  declarationMap: {
    optionType: optionTypeEnum.BOOLEAN,
    default: false,
    userChoice: null,
  },
  sourceMap: {
    optionType: optionTypeEnum.BOOLEAN,
    default: false,
    userChoice: null,
  },
  outFile: { optionType: optionTypeEnum.INPUT, default: "", userChoice: null },
  outDir: { optionType: optionTypeEnum.INPUT, default: "", userChoice: null },
  rootDir: { optionType: optionTypeEnum.INPUT, default: "", userChoice: null },
  composite: {
    optionType: optionTypeEnum.BOOLEAN,
    default: true,
    userChoice: null,
  },
  tsBuildInfoFile: {
    optionType: optionTypeEnum.INPUT,
    default: ".tsbuildinfo",
    userChoice: null,
  },
  removeComments: {
    optionType: optionTypeEnum.BOOLEAN,
    default: false,
    userChoice: null,
  },
  noEmit: {
    optionType: optionTypeEnum.BOOLEAN,
    default: false,
    userChoice: null,
  },
  importHelpers: {
    optionType: optionTypeEnum.BOOLEAN,
    default: false,
    userChoice: null,
  },
  downlevelIteration: {
    optionType: optionTypeEnum.BOOLEAN,
    default: false,
    userChoice: null,
  },
  isolatedModules: {
    optionType: optionTypeEnum.BOOLEAN,
    default: false,
    userChoice: null,
  },
};

const convertUserConfigToTsconfigFile = (userConfig: UserConfigInterface) => {
  const compilerOptions = Object.entries(userConfig).reduce(
    (acc, [optionKey, value]) => {
      const shouldDisplayKey =
        (Array.isArray(value.userChoice) && value.userChoice.length > 1) ||
        (!Array.isArray(value.userChoice) && value.userChoice !== null);
      return {
        ...acc,
        ...(shouldDisplayKey ? { [optionKey]: value.userChoice } : null),
      };
    },
    {}
  );

  const finalObject = { compilerOptions };
  const finalObjecStringified = JSON.stringify(finalObject, null, 2);

  return finalObjecStringified;
};

const options: (keyof typeof UserConfig)[] = [
  "incremental", // Basic Options (0)
  "target",
  "module",
  "lib",
  "allowJs",
  "checkJs",
  "jsx",
  "declaration",
  "declarationMap",
  "sourceMap",
  "outFile",
  "outDir",
  "rootDir",
  "composite",
  "tsBuildInfoFile",
  "removeComments",
  "noEmit",
  "importHelpers",
  "downlevelIteration",
  "isolatedModules",
  // "strict", // Strict Type Checkign Options (20)
  // "noImplicitAny",
  // "strictNullChecks",
  // "strictFunctionTypes",
  // "strictBindCallApply",
  // "strictPropertyInitialization",
  // "noImplicitThis",
  // "alwaysStrict",
  // "noUnusedLocals", // Additional Options (28)
  // "noUnusedParameters",
  // "noImplicitReturns",
  // "noFallthroughCasesInSwitch",
  // "moduleResolution", // Module Resolution Options (32)
  // "baseUrl",
  // "paths",
  // "rootDirs",
  // "typeRoots",
  // "types",
  // "allowSyntheticDefaultImports",
  // "esModuleInterop",
  // "preserveSymlinks",
  // "allowUmdGlobalAccess",
  // "sourceRoot", // Source Map Options (42)
  // "mapRoot",
  // "inlineSourceMap",
  // "inlineSources",
  // "experimentalDecorators", // Advanced Options (46)
  // "emitDecoratorMetadata"
];

const getTitleOfOptions = (index: number) => {
  switch (true) {
    case index < 20:
      return (
        <span>
          Basic <br />
          <span style={{ display: "flex", alignItems: "center" }}>
            Options{" "}
            <span style={{ fontSize: ".75em", marginLeft: ".5rem" }}>
              <b>{index + 1}</b>/20
            </span>
          </span>
        </span>
      );
    case index >= 20 && index < 28:
      return (
        <span>
          Strict Type Checking <br />
          <span style={{ display: "flex", alignItems: "center" }}>
            Options{" "}
            <span style={{ fontSize: ".75em", marginLeft: ".5rem" }}>
              <b>{index + 1 - 20}</b>/8
            </span>
          </span>
        </span>
      );
    case index >= 28 && index < 32:
      return (
        <span>
          Additional
          <br />
          <span style={{ display: "flex", alignItems: "center" }}>
            Options{" "}
            <span style={{ fontSize: ".75em", marginLeft: ".5rem" }}>
              <b>{index + 1 - 28}</b>/4
            </span>
          </span>
        </span>
      );
    case index >= 32 && index < 42:
      return (
        <span>
          Module Resolution
          <br />
          <span style={{ display: "flex", alignItems: "center" }}>
            Options{" "}
            <span style={{ fontSize: ".75em", marginLeft: ".5rem" }}>
              <b>{index + 1 - 32}</b>/10
            </span>
          </span>
        </span>
      );
    case index >= 42 && index < 46:
      return (
        <span>
          Source Map
          <br />
          <span style={{ display: "flex", alignItems: "center" }}>
            Options{" "}
            <span style={{ fontSize: ".75em", marginLeft: ".5rem" }}>
              <b>{index + 1 - 42}</b>/4
            </span>
          </span>
        </span>
      );
    case index >= 46:
      return (
        <span>
          Advanced
          <br />
          <span style={{ display: "flex", alignItems: "center" }}>
            Options{" "}
            <span style={{ fontSize: ".75em", marginLeft: ".5rem" }}>
              <b>{index + 1 - 46}</b>/3
            </span>
          </span>
        </span>
      );
    default:
      return "Options";
  }
};

function App() {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  let userConfig: UserConfigInterface;
  const [currentSearchTerm, setCurrentSearchTerm] = useState<string>("");
  let setUserConfig: React.Dispatch<React.SetStateAction<UserConfigInterface>>;
  [userConfig, setUserConfig] = usePersistedState<UserConfigInterface>(
    "key",
    UserConfig
  );

  const setUserConfigOption = (
    option: keyof UserConfigInterface,
    value: string | boolean | string[] | null
  ) => {
    setUserConfig((prevConfig) => {
      const newConfig = {
        ...prevConfig,
        [option]: { ...prevConfig[option], userChoice: value },
      };
      return newConfig;
    });
  };

  const resetCurrentSearchTerm = () => {
    setCurrentSearchTerm("");
  };
  const divRef = useRef<HTMLDivElement>(null);
  const logAndProcessSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    setCurrentSearchTerm(e.currentTarget.value);
    const indexBeforeSearch = activeIndex;
    const searchTerm = e.currentTarget.value;
    console.log("searchTerm: ", searchTerm);
    const filteredOption = options.filter((option) => {
      return option.startsWith(searchTerm);
    })[0];

    console.log("filteredOption: ", filteredOption);
    const index = options.indexOf(filteredOption);
    console.log("<><> index");
    console.log("<><>", index);
    setActiveIndex(index === -1 ? indexBeforeSearch : index);
  };

  const [theme, setTheme] = useState<"dark" | "light">("dark");

  const toggleTheme = () => {
    console.log("toggleTheme");
    setTheme((theme) => (theme === "dark" ? "light" : "dark"));
  };

  return (
    <div className="app">
      <header>
        <span className="title-header">
          <h1 onClick={toggleTheme} className="logo">
            tsconfig.json <span className="text">generator</span>
          </h1>
          <span className="subtext" style={{ fontSize: "1rem" }}>
            Download your generated{" "}
            <code style={{ color: "#00c59f" }}>tsconfig.json</code> file{" "}
            <a
              href={
                "data:text/json;charset=utf-8," +
                encodeURIComponent(convertUserConfigToTsconfigFile(userConfig))
              }
              download={"tsconfig.json"}
            >
              <i
                style={{ color: "#00c59f", fontSize: "1.5rem" }}
                className="far fa-download"
              ></i>
            </a>
          </span>
        </span>
        <h3 className="optionsHeader">{getTitleOfOptions(activeIndex)}</h3>
        <h2 className="descriptionsHeader">DESCRIPTIONS</h2>
        <div className="search">
          <i className="far fa-search mr-1"></i>
          <input
            type="search"
            value={currentSearchTerm}
            onChange={logAndProcessSearch}
          />
        </div>
        <div className="links">
          <a href="https://medium.com/@alextzinov/d8f333e578c1">
            <i className="fab fa-medium-m"></i>
          </a>
          <a href="https://github.com/Tzinov15/tsconfig-demystified">
            <i className="fab fa-github"></i>
          </a>
        </div>
      </header>
      <main>
        <div className="left">
          <TSXOptionsComponent
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
            currentSearchTerm={currentSearchTerm}
            resetCurrentSearchTerm={resetCurrentSearchTerm}
            options={options}
            ref={divRef}
          />
          <div className="overlay" />
        </div>
        <div className="right">
          <TSXDescriptionsComponent
            UserConfig={userConfig}
            setUserConfigOption={setUserConfigOption}
            activeIndex={activeIndex}
            options={options}
          />
        </div>
      </main>
    </div>
  );
}

export default App;
