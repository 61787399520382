import React from "react";
import { MAPPINGS } from "./optionDescriptionMappings";

import "./tsxDescriptionsComponent.css";
import { UserConfigInterface, optionTypeEnum } from "./App";

const BooleanBadge: React.FC<{
  type: false | true;
  active: boolean;
  isDefault: boolean;
  onChoicePress: (value: string | boolean | string[] | null) => void;
  currentUserChoice: string | boolean | string[] | null;
}> = ({
  type,
  active,
  isDefault,
  onChoicePress,
  currentUserChoice,
}: {
  type: false | true;
  active: boolean;
  isDefault: boolean;
  onChoicePress: (value: string | boolean | string[] | null) => void;
  currentUserChoice: string | boolean | string[] | null;
}) => {
  console.log(currentUserChoice);

  return (
    <div
      tabIndex={0}
      onClick={(e) => onChoicePress(type)}
      className={`badge badge-enum ${
        isDefault &&
        (currentUserChoice === null ||
          (Array.isArray(currentUserChoice) && currentUserChoice.length === 0))
          ? `param-will-be-default`
          : isDefault
          ? "param-default"
          : ""
      } ${active ? `param-active` : ""}`}
    >
      {String(type)}
    </div>
  );
};

const EnumBadge: React.FC<{
  active: boolean;
  isDefault: boolean;
  value: string;
  onChoicePress: (value: string | boolean | string[] | null) => void;
  currentUserChoice: string | boolean | string[] | null;
}> = ({
  active,
  isDefault,
  value,
  onChoicePress,
  currentUserChoice,
}: {
  active: boolean;
  isDefault: boolean;
  value: string;
  onChoicePress: (value: string | boolean | string[] | null) => void;
  currentUserChoice: string | boolean | string[] | null;
}) => (
  <div
    onClick={(e) => onChoicePress(value)}
    tabIndex={0}
    className={`badge badge-enum ${
      isDefault &&
      (currentUserChoice === null ||
        (Array.isArray(currentUserChoice) && currentUserChoice.length === 0))
        ? "param-will-be-default"
        : isDefault
        ? "param-default"
        : ""
    } ${active ? "param-active" : ""}`}
  >
    {value}
  </div>
);

const Params = ({
  optionKey,
  UserConfig,
  setUserConfigOption,
}: {
  optionKey: keyof typeof UserConfig;
  UserConfig: UserConfigInterface;

  setUserConfigOption: (
    option: keyof UserConfigInterface,
    value: string | boolean | string[] | null
  ) => void;
}) => {
  const usersChoice = UserConfig[optionKey].userChoice;

  const onChoicePress = (value: string | boolean | string[] | null) => {
    const optionType = UserConfig[optionKey].optionType;
    const currentUserOption = UserConfig[optionKey].userChoice;
    if (optionType === optionTypeEnum.ENUM_MULTIPLE) {
      const currentUserOption: (string | null)[] = UserConfig[optionKey]
        .userChoice
        ? (UserConfig[optionKey].userChoice as string[])
        : ["ES5"];
      if (!usersChoice) {
        setUserConfigOption(optionKey, [value ? String(value) : "ES5"]);
      } else if ((usersChoice as (string | null)[]).includes(value as string)) {
        // @ts-ignore
        currentUserOption.splice(currentUserOption.indexOf(value), 1);

        // @ts-ignore
        setUserConfigOption(optionKey, currentUserOption);
      } else {
        // @ts-ignore
        setUserConfigOption(optionKey, [...currentUserOption, value]);
      }
    }
    if (optionType === optionTypeEnum.ENUM_SINGLE) {
      const currentUserOption = UserConfig[optionKey].userChoice;
      if (!usersChoice) {
        setUserConfigOption(optionKey, value);
      }

      if (usersChoice === value) {
        setUserConfigOption(optionKey, null);
      } else {
        setUserConfigOption(optionKey, value);
      }
    }
    if (optionType === optionTypeEnum.BOOLEAN) {
      const currentUserOption = UserConfig[optionKey].userChoice;
      if (usersChoice === null) {
        setUserConfigOption(optionKey, value);
      } else if (usersChoice === true && value === true) {
        setUserConfigOption(optionKey, null);
      } else if (usersChoice === false && value === false) {
        setUserConfigOption(optionKey, null);
      } else {
        setUserConfigOption(optionKey, value);
      }
    }
  };

  switch (UserConfig[optionKey].optionType) {
    case optionTypeEnum.ENUM_MULTIPLE:
      return (
        <div className="paramBadgeHolder">
          {MAPPINGS[optionKey].params.map((param) => {
            return (
              <EnumBadge
                onChoicePress={onChoicePress}
                key={param}
                value={param}
                isDefault={(UserConfig[optionKey].default as string[]).includes(
                  param
                )}
                active={(UserConfig[optionKey].userChoice as string[]).includes(
                  param
                )}
                currentUserChoice={UserConfig[optionKey].userChoice}
              />
            );
          })}
        </div>
      );

    case optionTypeEnum.ENUM_SINGLE:
      return (
        <div className="paramBadgeHolder">
          {MAPPINGS[optionKey].params.map((param) => {
            return (
              <EnumBadge
                onChoicePress={onChoicePress}
                key={param}
                value={param}
                isDefault={UserConfig[optionKey].default === param}
                active={UserConfig[optionKey].userChoice === param}
                currentUserChoice={UserConfig[optionKey].userChoice}
              />
            );
          })}
        </div>
      );
    case optionTypeEnum.BOOLEAN:
      return (
        <div className="paramBadgeHolder">
          <BooleanBadge
            currentUserChoice={UserConfig[optionKey].userChoice}
            onChoicePress={onChoicePress}
            isDefault={UserConfig[optionKey].default === true}
            type={true}
            active={usersChoice === true}
          />
          <BooleanBadge
            currentUserChoice={UserConfig[optionKey].userChoice}
            onChoicePress={onChoicePress}
            isDefault={UserConfig[optionKey].default === false}
            type={false}
            active={usersChoice === false}
          />
        </div>
      );

    case optionTypeEnum.INPUT:
      return (
        <div className="pathInput">
          <i className="far fa-terminal mr-2"></i>
          <input
            type="input"
            placeholder="<path>"
            value={UserConfig[optionKey].userChoice as string}
            onChange={(e) =>
              setUserConfigOption(optionKey, e.currentTarget.value)
            }
          />
        </div>
      );
    default:
      return (
        <div className="paramBadgeHolder">
          {MAPPINGS[optionKey].params.map((param) => {
            return (
              <EnumBadge
                onChoicePress={onChoicePress}
                key={param}
                value={param}
                isDefault={UserConfig[optionKey].default === param}
                active={UserConfig[optionKey].userChoice === param}
                currentUserChoice={UserConfig[optionKey].userChoice}
              />
            );
          })}
        </div>
      );
  }
};

const Title = ({ option }: { option: keyof UserConfigInterface }) => {
  return <h2 className="title">{option}</h2>;
};

const TLDR = ({ option }: { option: keyof UserConfigInterface }) => {
  return (
    <div className="tldr">
      <h4 className="">
        <b>TL;DR </b>
      </h4>
      <p>{MAPPINGS[option].tldr}</p>
    </div>
  );
};
const WhenToUse = ({ option }: { option: keyof UserConfigInterface }) => {
  return (
    <div className="whentouse">
      <h4 className="">
        <b>When to Use: </b>
      </h4>
      <p>{MAPPINGS[option].whenToUse}</p>
    </div>
  );
};

export default function TSXDescriptionsContainer({
  activeIndex,
  options,
  UserConfig,
  setUserConfigOption,
}: {
  activeIndex: number;
  options: (keyof typeof UserConfig)[];
  UserConfig: UserConfigInterface;
  setUserConfigOption: (
    option: keyof UserConfigInterface,
    value: string | boolean | string[] | null
  ) => void;
}) {
  const option = options[activeIndex];
  console.log("Current Option in the Descriptions Component: ", option);

  return (
    <div style={{ position: "relative" }}>
      <Title option={option} />
      <TLDR option={option} />
      <WhenToUse option={option} />
      <h4 className="paramsTitle">
        Params{" "}
        <span style={{ fontSize: ".5em" }}>
          (click on parameter buttons to build up your config)
        </span>
      </h4>
      <Params
        setUserConfigOption={setUserConfigOption}
        UserConfig={UserConfig}
        optionKey={option}
      ></Params>
    </div>
  );
}
