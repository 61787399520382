import React from "react";

interface Mappings {
  [index: string]: {
    boolean?: boolean;
    input?: boolean;
    params: string[];
    tldr: string;
    whenToUse: string;
  };
}
const incremental = {
  whenToUse: "When you want to make your TS builds faster.",
  tldr: "Caches project compipplation info to make recompiles faster. ",
  boolean: true,
  params: [],
};

const target = {
  whenToUse:
    "When your app needs to run in environments that only support a particular (older) version of JavaScript, you specify that version here.",
  tldr: "What version of JavaScript to transpile your TS code to.",
  params: [
    "ES3",
    "ES5",
    "ES2015",
    "ES2016",
    "ES2017",
    "ES2018",
    "ES2019",
    "ES2020",
    "ESNEXT",
  ],
};

const _module = {
  whenToUse:
    "Safe to leave as default unless you are targeting browsers and not using a bundler (eg: Webpack) that can understand CommonJS or plan on targeting newer environments and want to use ES6 Modules.",
  tldr: "The module system³ that the outputted JS will operate under.",
  params: [
    "none",
    "commonjs",
    "amd",
    "system",
    "umd",
    "es2015",
    "es2020",
    "ESNext",
  ],
};

const lib = {
  whenToUse:
    "When you need to support older versions of JavaScript but intend to include newer features (Promise, Array functions, etc) at runtime and need to tell the compiler they will be included",
  tldr:
    "The set of language features (in the form of type definitions) you want TypeScript to include and compile (and thus assume will be provided at runtime) so that your code can use (and your IDE understand) these newer features. ",
  params: [
    "ES5",
    "ES6",
    "ES2015",
    "ES7",
    "ES2016",
    "ES2017",
    "ES2018",
    "ESNext",
    "DOM",
    "DOM.Iterable",
    "WebWorker",
    "ScriptHost",
    "ES2015.Core",
    "ES2015.Collection",
    "ES2015.Generator",
    "ES2015.Iterable",
    "ES2015.Promise",
    "ES2015.Proxy",
    "ES2015.Reflect",
    "ES2015.Symbol",
    "ES2015.Symbol.WellKnown",
    "ES2016.Array.Include",
    "ES2017.object",
    "ES2017.Intl",
    "ES2017.SharedMemory",
    "ES2017.String",
    "ES2017.TypedArrays",
    "ES2018.Intl",
    "ES2018.Promise",
    "ES2018.RegExp",
    "ESNext.AsyncIterable",
    "ESNext.Array",
    "ESNext.Intl",
    "ESNext.Symbol",
  ],
};

const allowJs = {
  whenToUse:
    "When you are incrementally migrating from JS → TS or have legacy JS you don't want to migrate and want to allow this code to still exist.",
  tldr: "Allows JavaScript files to co-exist in a TypeScript project.",
  boolean: true,
  params: [],
};

const checkJs = {
  whenToUse:
    "When you want to emit type errors in your JS files without fully converting them to TS",
  tldr: "Type check JS files as well",
  boolean: true,
  params: [],
};

const jsx = {
  whenToUse:
    "When you don't want typescript managing your jsx and want tsc to leave it untouched in the transpiled js or when you want to support react native, otherwise leave default",
  tldr: "What to do with jsx⁴ in your code ",
  boolean: true,
  params: ["preserve", "react", "react-native"],
};
const declaration = {
  whenToUse:
    "Generate type definition files for your source to be imported elsewhere ",
  tldr:
    "When you are writing a library and want to be a courteous member of the TypeScript community and provide consumers of your library with types to improve their developer experience and help make their code safer.",
  boolean: true,
  params: [],
};

const declarationMap = {
  tldr: "Generate mappings to tie definition files (.d.ts) to source (.ts)",
  whenToUse:
    "When you want to further improve the developer experience of the consumer of your library by letting them jump not just to your libraries types but to their implementation as well.}",
  boolean: true,
  params: [],
};
const sourceMap = {
  whenToUse:
    "When you want to emit type errors in your JS files without fully converting them to TS",
  tldr: "Generate mappings to tie outputted code (.js) to source code (.ts)",
  boolean: true,
  params: [],
};
const outFile = {
  whenToUse:
    "When you are using system or amd modules and want to concatenate all modules into a singe file",
  tldr: "Combines all amd or system modules into a single file",
  input: true,
  params: [],
};

const outDir = {
  whenToUse:
    "When you want to dictate where outputted files go and not leave the default value which is in the same directory as the source code",
  tldr: "Where should outputted/transpiled files go",
  input: true,
  params: [],
};
const rootDir = {
  whenToUse: "When you want to update the rootDir",
  tldr: "The root of your project from tsc's perspective",
  input: true,
  params: [],
};

const composite = {
  whenToUse:
    "When you have nested TypeScript projects that depend on each other as part of their build process, often used when you want to break apart a large TS project into smaller sub-projects to improve compile times",
  tldr:
    "Allows a TypeScript project (or sub-directory that is to be treated as a separate TS project) to be referenced from a separate TS project",
  boolean: true,
  params: [],
};

const tsBuildInfoFile = {
  whenToUse:
    "When you want to change the default behavior of saving to .tsbuildinfo",
  tldr:
    "The name of the file where the project compile cache information will be saved to. ",
  input: true,
  params: [],
};

const removeComments = {
  whenToUse: "When you want to remove sensitive comments or reduce bundle size",
  tldr:
    "Strips comments from source code and does not include them in transpiled JS",
  boolean: true,
  params: [],
};

const noEmit = {
  whenToUse:
    "When you want to use the TypeScript compiler for type-checking and IDE support only and would like a later step (Babel/Webpack) to take care of the actual transpilation}",
  tldr: "Do not emit any compiled JS or declaration files",
  boolean: true,
  params: [],
};

const importHelpers = {
  whenToUse:
    "When you want to reduce your file size by making your outputted JS more DRY and have helpers that are otherwise injected into each file come from a centralized lib}",
  tldr:
    "Will replace inline helper function implementations for things like async/await and Object spread operator that are otherwise redefined and injected into each file with the respective function call from the centralized tslib library",
  boolean: true,
  params: [],
};

const downlevelIteration = {
  whenToUse:
    "When you want to ensure that iterables are transpiled to a correct implementation in ES3/ES5",
  tldr:
    "Include a more sophisticated and comprehensive implementation for syntax like for item of collection that will work under all use cases in ES3 and ES5",
  boolean: true,
  params: [],
};

const isolatedModules = {
  whenToUse:
    "When you want to emit type errors in your JS files without fully converting them to TS",
  tldr: "Type check JS files as well",
  boolean: true,
  params: [],
};

export const MAPPINGS: Mappings = {
  incremental,
  target,
  module: _module,
  lib,
  allowJs,
  checkJs,
  jsx,
  declaration,
  declarationMap,
  sourceMap,
  outFile,
  outDir,
  rootDir,
  composite,
  tsBuildInfoFile,
  removeComments,
  noEmit,
  importHelpers,
  downlevelIteration,
  isolatedModules,
};
