import { useState, useEffect, Dispatch, SetStateAction } from 'react'

type usePersistedStateInterface<T> = (key: string, defaultValue: T) => [T, Dispatch<SetStateAction<T>>]


export function usePersistedState<T>(key: string, defaultValue: T): [T, Dispatch<SetStateAction<T>>] {

    const [state, setState] = useState<T>(
        () => {
            try {

                return JSON.parse(localStorage.getItem(key) || JSON.stringify(defaultValue)) || defaultValue
            } catch (err) {
                console.log('err');
                console.log(err);
            }
        }
    );
    useEffect(() => {
        localStorage.setItem(key, JSON.stringify(state));
    }, [key, state]);
    return [state, setState];
}
