import React, { useEffect, useRef, useState } from "react";
import * as _ from "lodash";

import "./tsxOptionsComponent.css";
import { log } from "util";

const RenderOption = ({
  option,
  index,
  activeOptionIndex,
  offset,
  setActiveIndex,
}: {
  option: string;
  index: number;
  activeOptionIndex: number;
  offset: number;
  setActiveIndex: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const optionRef = useRef<HTMLHeadingElement>(null);
  const [isActiveOption, setActiveOption] = useState(false);
  const getIndexFromOffset = (offset: number) => {
    return Math.ceil(offset / 72) - 1;
  };
  useEffect(() => {
    if (optionRef.current) {
      const offset =
        optionRef.current?.getBoundingClientRect().top +
        optionRef.current?.scrollTop;
      // if (getIndexFromOffset(offset) === index) {
      //   setActiveOption(true);
      // } else if (index === activeOptionIndex) {
      //   const offset = optionRef.current?.getBoundingClientRect().top;
      //   setActiveOption(true);
      // } else {
      //   setActiveOption(false);
      // }
      // const offset =
      //   optionRef.current?.getBoundingClientRect().top +
      //   optionRef.current?.scrollTop;

      // //  150px for header, 24 for margin top of option, 1 px for border below header
      if (103 <= offset && offset <= 175) {
        setActiveOption(true);
        setActiveIndex(index);
      } else {
        setActiveOption(false);
      }
      // if (103 <= offset && offset <= 175) {
      //   setActiveOption(true);
      //   setActiveIndex(index);
      // } else {
      //   setActiveOption(false);
      //   // setActiveIndex(index);
      // }
    }
  }, [offset, index, setActiveIndex]);

  const thresholdIndexes = [19, 27, 31, 41, 45];
  return (
    <h1
      ref={optionRef}
      key={option}
      className={`option ${
        thresholdIndexes.includes(index) ? "option-category-end" : ""
      } option-${isActiveOption ? "active" : "base"}`}
    >
      {option}
    </h1>
  );
};
export default function TSXOptionsContainer({
  activeIndex,
  setActiveIndex,
  options,
  currentSearchTerm,
  resetCurrentSearchTerm,
  ref,
}: {
  activeIndex: number;
  setActiveIndex: React.Dispatch<React.SetStateAction<number>>;
  options: string[];
  ref: React.RefObject<HTMLDivElement>;
  currentSearchTerm: string;
  resetCurrentSearchTerm: () => void;
}) {
  const getIndexFromOffset = (offset: number) => {
    console.log("offset", offset);
    return Math.ceil(offset / 72) - 1 === -1 ? 0 : Math.ceil(offset / 72);
  };

  const eventListener = (e: Event) => {
    if (divRef.current) {
      const activeIndex = getIndexFromOffset(divRef.current.scrollTop);

      // if (!currentSearchTerm) {
      // setActiveIndex(activeIndex);
      // }
      // console.log('scrollTop of container: ', divRef.current.scrollTop)
      setCurrentOffset(divRef.current.scrollTop);

      /* An element's scrollTop value is a measurement of the distance from the element's top
       to its topmost visible content. When an element's content does not generate a vertical scrollbar, 
       then its scrollTop value is 0.*/
    }
  };

  const newListener = _.throttle(eventListener, 100);

  const [currentlyScrolling, setCurrentlyScrolling] = useState<boolean>(false);
  useEffect(() => {
    const listener = divRef.current?.addEventListener("scroll", eventListener, {
      passive: true,
    });
    return () => {
      divRef.current?.removeEventListener("scroll", eventListener);
    };
  }, [currentlyScrolling]);

  // useEffect(() => {
  //   if (divRef.current) {
  //     setCurrentlyScrolling(true);
  //     divRef.current.scrollTo({ top: activeIndex * 72, behavior: "smooth" });
  //     setTimeout(() => setCurrentlyScrolling(false), 600);
  //   }
  // }, [activeIndex]);
  const divRef = useRef<HTMLDivElement>(null);
  //   const [activeOptionIndex, setAcitveOptionIndex] = useState<number>(0);
  const [currentOffset, setCurrentOffset] = useState<number>(0);
  setTimeout(() => {
    console.log("height");
    console.log(divRef.current?.offsetHeight);
    const newHeight =
      Math.floor((divRef.current?.offsetHeight || 40000) / 72) * 72;
    console.log(newHeight);
  });
  return (
    <div ref={divRef} className="optionsContainer">
      {options.map((option, index) => (
        <RenderOption
          key={option}
          option={option}
          index={index}
          activeOptionIndex={activeIndex}
          setActiveIndex={setActiveIndex}
          offset={currentOffset}
        />
      ))}
      {activeIndex !== options.length - 1 && (
        <i className="far fa-angle-double-down scroll-arrow-bottom"></i>
      )}
      {activeIndex !== 0 && (
        <i className="far fa-angle-double-up scroll-arrow-top"></i>
      )}
      <div
        style={{
          height: `${
            Math.floor((divRef.current?.offsetHeight || 40000) / 72 - 1) * 72
          }px`,
        }}
      />
      {/* <div style={{ height: "432px" }} /> */}
    </div>
  );
}
